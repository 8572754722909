import { Auth } from "aws-amplify";
import { For, Match, Show, Switch, useContext } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { A } from "@solidjs/router"
import { createFormGroup, createFormControl } from "solid-forms";

import { CheckboxInput } from "../../../components/CheckboxInput";
import { TextInput } from "../../../components/TextInput";
import { validators } from "../../../helpers/validators";
import { UserContext } from "../../../components/UserContext";
import { AuthSignInErrorCode } from "../../../helpers/types";

export function SignInForm() {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const group = createFormGroup({
    usernameOrEmail: createFormControl("", {
      required: true,
      validators: [validators.usernameOrEmail, validators.required],
    }),
    password: createFormControl("", {
      required: true,
      validators: [validators.required],
    }),
  });

  async function signIn(usernameOrEmail: string, password: string, group: any) {
    try {
      await Auth.signIn(usernameOrEmail ?? "", password ?? "");
    } catch (error) {
      const { code, message }: {
        code: AuthSignInErrorCode | undefined;
        message: string | undefined;
      } = error;

      console.log("error signing in: ", error);

      group.markSubmitted(false);
      switch (code) {
        case AuthSignInErrorCode.USER_NOT_FOUND:
          group.setErrors({
            userNotFound: "No user with that username.",
          });
          break;
        case AuthSignInErrorCode.USER_NOT_AUTHORIZED:
          group.setErrors({
            usernameAlreadyExists: "Incorrect username or password.",
          });
          break;
        case AuthSignInErrorCode.USER_NOT_CONFIRMED:
          group.setErrors({
            userNotConfirmed: "Please confirm signup email before logging in.",
          });
          break;
        case AuthSignInErrorCode.USER_REQUIRES_PASSWORD_RESET:
          group.setErrors({
            userNotConfirmed:
              "Please perform a password reset before logging in.",
          });
          break;
        default:
          group.setErrors({
            unknownError: message,
          });
      }

      return;
    }

    await user?.refetchUserData();
    navigate("/dashboard", { replace: true });
  }

  const submitHandler = (e: Event) => {
    e.preventDefault();

    if (group.isSubmitted) {
      return;
    }

    // Need to clear group-wide errors so form is submitted.
    group.setErrors(null);

    if (!group.isValid) {
      console.warn("Invalid form");
      return;
    }

    group.markSubmitted(true);

    const { usernameOrEmail, password } = group.value;
    signIn(usernameOrEmail ?? "", password ?? "", group);
  };

  return (
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Sign in to your account
        </h2>

        <Show when={import.meta.env.VITE_ENABLE_REGISTRATION === "true"}>
          <p class="mt-2 text-center text-sm text-gray-600">
            <A
              class="font-medium text-nl-blue-royal-900 hover:text-nl-blue-royal-700"
              href="/sign-up"
            >
              Or create an account
            </A>
          </p>
        </Show>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={submitHandler} class="space-y-6">
            <div>
              <label
                for="usernameOrEmail"
                class="block text-sm font-medium text-gray-700"
              >
                Username or Email Address
              </label>
              <div class="mt-1">
                <TextInput
                  autocomplete="email username"
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-700 focus:outline-none focus:ring-nl-blue-royal-700 sm:text-sm"
                  control={group.controls.usernameOrEmail}
                  name="usernameOrEmail"
                  type="text"
                />
              </div>

              <Show when={group.controls.usernameOrEmail.errors}>
                <div class="mt-1">{group.controls.usernameOrEmail.errors}</div>
              </Show>
            </div>

            <div>
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div class="mt-1">
                <TextInput
                  autocomplete="current-password"
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-700 focus:outline-none focus:ring-nl-blue-royal-700 sm:text-sm"
                  control={group.controls.password}
                  name="password"
                  type="password"
                />
              </div>

              <Show when={group.controls.password.errors}>
                <div class="mt-1">{group.controls.password.errors}</div>
              </Show>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <CheckboxInput
                  class="h-4 w-4 rounded border-gray-300 text-nl-blue-royal-900 focus:ring-nl-blue-royal-700"
                  name="remember-me"
                />
                <label
                  for="remember-me"
                  class="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <Show
                when={import.meta.env.VITE_ENABLE_FORGOT_PASSWORD === "true"}
              >
                <div class="text-sm">
                  <A
                    class="font-medium text-nl-blue-royal-900 hover:text-nl-blue-royal-700"
                    href="/sign-in/forgot-password"
                  >
                    Forgot your password?
                  </A>
                </div>
              </Show>
            </div>

            <Show when={!group.self.isValid}>
              <div class="rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg
                      class="h-5 w-5 text-red-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                      <Switch fallback={"There were errors during sign in"}>
                        <Match
                          when={Object.values(group.self.errors).length === 1}
                        >
                          There was an error with your submission
                        </Match>
                        <Match
                          when={Object.values(group.self.errors).length > 1}
                        >
                          There were {Object.values(group.self.errors).length}{" "}
                          errors with your submission
                        </Match>
                      </Switch>
                    </h3>
                    <div class="mt-2 text-sm text-red-700">
                      <ul role="list" class="list-disc space-y-1 pl-5">
                        <For each={Object.values(group.self.errors)}>
                          {(errorMsg: string) => <li>{errorMsg}</li>}
                        </For>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Show>

            <div>
              <button
                type="submit"
                class="flex w-full justify-center rounded-md border border-transparent bg-nl-blue-royal-900 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-nl-blue-royal-700 focus:outline-none focus:ring-2 focus:ring-nl-blue-royal-700 focus:ring-offset-2"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
