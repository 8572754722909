/* @refresh reload */
import { Amplify } from "aws-amplify";
import { render } from "solid-js/web"; // eslint-disable-line import/named
import { Router } from "@solidjs/router";
import { getAmplifyConfig } from "./utils/amplify";
import App from "./App";
import "./index.css";
import { Layout } from "./components/Layout";

Amplify.configure(getAmplifyConfig());

render(
  () => (
    <Router root={Layout}>
      <App />
    </Router>
  ),
  document.getElementById("app") as HTMLElement,
);
