import {
  Accessor,
  Setter,
  Show,
  createEffect,
  createSignal,
  onCleanup,
} from "solid-js";
import {
  MachineList,
  MachineMinimalType,
  SingleMachineType,
} from "../../../helpers/types";
import { CopyIcon } from "../../../components/Icons";
import { GraphQLClientQuery } from "@solid-primitives/graphql";
import { SetStoreFunction } from "solid-js/store";
import { _UpdateMachine } from "../../../helpers/mutations";
import UpdateConfirmationBox from "../../../components/UpdateConfirmationBox";
import ChangePageConfirmationBox from "../../../components/ChangePageConfirmationBox";
import configtemplate from "../../../utils/configtemplate.json";

interface ConfigMachineProps {
  updatableMachine: SingleMachineType;
  setUpdatableMachine: SetStoreFunction<SingleMachineType>;
  machineId: Accessor<string>;
  refetchMachines: (
    info?: unknown,
  ) => MachineList | Promise<MachineList | undefined> | null | undefined;
  showOverview: Setter<boolean>;
  changesMade: Accessor<boolean>;
  setChangesMade: Setter<boolean>;
  originalConfig: Accessor<
    { machineID: string; originalConfig: string } | undefined
  >;
  setOriginalConfig: Setter<
    { machineID: string; originalConfig: string } | undefined
  >;
  client: GraphQLClientQuery;
}

const formatConfigData = (config: string) => {
  if (config !== null) {
    const jsonObject = JSON.parse(config);
    return JSON.stringify(jsonObject, null, 2);
  } else {
    return JSON.stringify(configtemplate, null, 2);
  }
};

export default function ConfigMachineOverview(props: ConfigMachineProps) {
  var configInput!: HTMLTextAreaElement;
  var nameInput!: HTMLInputElement;

  var defaultConfig = formatConfigData(props.updatableMachine.config);

  //For confirmationboxes
  const [showUpdateConfirmationBox, setShowUpdateConfirmationBox] =
    createSignal(false);
  const [showCancelConfirmationBox, setShowCancelConfirmationBox] =
    createSignal<boolean>(false);

  //For keeping track of changes
  const [nameIsChanged, setNameIsChanged] = createSignal<boolean>(false);
  const [configIsChanged, setConfigIsChanged] = createSignal<boolean>(false);
  const [correctFormat, setCorrectFormat] = createSignal<boolean | undefined>(
    undefined,
  );

  //Set to false as not to fire on mount
  const [updateQueryInput, setUpdateQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [updatedMachineResponse] = props.client<MachineMinimalType>(
    _UpdateMachine,
    updateQueryInput,
  );

  //Subscribers to changes
  createEffect(() => {
    if (
      !updatedMachineResponse.loading &&
      !updatedMachineResponse.error &&
      updatedMachineResponse()
    ) {
      props.setOriginalConfig({
        machineID: props.updatableMachine.id,
        originalConfig: defaultConfig,
      });
      props.refetchMachines();
      props.setUpdatableMachine("id", "");

      console.log(
        props.updatableMachine.id + " : " + props.originalConfig()?.machineID,
      );

      props.setChangesMade(false);
      setUpdateQueryInput(false);
      props.showOverview(false);
    }
  });

  createEffect(() => {
    if (!props.changesMade()) {
      setCorrectFormat(undefined);
    }
  });

  createEffect(() => {
    if (props.machineId()) {
      nameInput.value = "";
      setNameIsChanged(false);
      defaultConfig = formatConfigData(props.updatableMachine.config);
      setConfigIsChanged(false);
    }
  });

  createEffect(() => {
    if (nameIsChanged() || configIsChanged()) {
      props.setChangesMade(true);
    } else {
      props.setChangesMade(false);
    }
  });

  //Handlers and functions
  const cancelPage = () => {
    props.setChangesMade(false);
    props.showOverview(false);
    window.location.href = "#machineoverview";
  };

  const handleBeforeUnload = (event: any) => {
    if (props.changesMade()) event.preventDefault();
  };

  window.addEventListener("beforeunload", handleBeforeUnload);

  onCleanup(() => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  });

  const nameInputHandler = () => {
    if (nameInput.value !== props.updatableMachine.name) {
      setNameIsChanged(true);
    }
    if (
      nameInput.value === props.updatableMachine.name ||
      nameInput.value === ""
    ) {
      setNameIsChanged(false);
    }
  };

  const configInputHandler = () => {
    if (configInput.value !== defaultConfig) {
      try {
        setConfigIsChanged(true);
        formatConfigData(configInput.value);
        setCorrectFormat(true);
      } catch (error) {
        setCorrectFormat(false);
      }
    }
    if (configInput.value === defaultConfig) {
      setCorrectFormat(undefined);
      setConfigIsChanged(false);
    }
  };

  const updateMachineHandler = () => {
    if ((configIsChanged() && correctFormat()) || nameIsChanged()) {
      setShowUpdateConfirmationBox(true);
    }
  };

  const updateMachine = () => {
    const updateMachineId = props.updatableMachine.id;
    var input: any;

    if (nameInput.value !== "") {
      input = {
        name: nameInput.value,
        config: configInput.value,
      };
    } else {
      input = { config: configInput.value };
    }
    setUpdateQueryInput({ updateMachineId, input });
    window.location.href = "#machineoverview";
  };

  return (
    <div class="mx-auto my-10 w-10/12 bg-white shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm">
      <div id="singlemachineoverview">
        <h2 class="px-4 pb-2 pt-4 text-xl font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 bg-gray-50 bg-opacity-75 ">
          {props.updatableMachine.name}
        </h2>
      </div>
      <Show when={updatedMachineResponse.error}>
        <p class="block text-sm font-medium text-red-700 mt-10 ml-10">
          *Something went wrong, could not update. Try again or reload page.
        </p>
      </Show>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="w-1/2 p-5">
            <label
              for="machineName"
              class="block font-medium text-gray-700 px-5 mt-5 mb-1"
            >
              Name
            </label>
            <input
              class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
              classList={{
                "border-2 border-green-400 shadow-sm": nameIsChanged(),
              }}
              id="machineName"
              type="text"
              ref={nameInput}
              placeholder={props.updatableMachine.name}
              onInput={() => nameInputHandler()}
            />
          </div>

          <div class="w-1/2 p-5">
            <div class="my-5">
              <h5 class="mx-5 block font-medium text-gray-700">Details</h5>
              <div class="mx-5 mt-3">
                <div class="block text-sm">
                  <p class=" font-medium text-gray-700">ID</p>
                  <div class="flex flex-row items-center">
                    <p class="text-gray-500">{props.updatableMachine.id} </p>
                    <a
                      onClick={() =>
                        navigator.clipboard.writeText(props.updatableMachine.id)
                      }
                      class="cursor-pointer active:animate-ping active:text-green-400 mx-1"
                    >
                      <CopyIcon />
                    </a>
                  </div>
                </div>
                <div class="block text-sm my-2">
                  <p class=" font-medium text-gray-700">Created</p>
                  <p class="text-gray-500">
                    {new Date(
                      props.updatableMachine.createdAt,
                    ).toLocaleDateString("sv-SE")}{" "}
                    |{" "}
                    {new Date(
                      props.updatableMachine.createdAt,
                    ).toLocaleTimeString("sv-SE")}{" "}
                  </p>
                </div>
                <div class="block text-sm my-2">
                  <p class=" font-medium text-gray-700">Last updated</p>
                  <p class="text-gray-500">
                    {new Date(
                      props.updatableMachine.updatedAt,
                    ).toLocaleDateString("sv-SE")}{" "}
                    |{" "}
                    {new Date(
                      props.updatableMachine.updatedAt,
                    ).toLocaleTimeString("sv-SE")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <label for="config" class="mx-5 font-medium text-gray-700">
          Configuration
        </label>
        <textarea
          class="block rounded-md min-h-screen  mx-5 mt-2 p-5 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm"
          classList={{
            "border-2 focus:border-green-400 focus:ring-green-400 border-green-400":
              correctFormat() && props.changesMade(),
            "border-2 focus:border-red-400 focus:ring-red-400 border-red-400":
              !correctFormat() && correctFormat() !== undefined,
            "border-2 border-gray-300": correctFormat() === undefined,
          }}
          onInput={() => {
            configInputHandler();
          }}
          value={formatConfigData(props.updatableMachine.config)}
          ref={configInput}
        />
      </div>

      <div class="flex flex-row justify-center p-5">
        <a
          class="nl-button nl-button--xs m-1 cursor-pointer"
          classList={{
            "opacity-100": props.changesMade(),
            "opacity-50 pointer-events-none": !props.changesMade(),
          }}
          onClick={() => updateMachineHandler()}
        >
          Update
        </a>
        <a
          class="nl-button nl-button--xs m-1  text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
          onClick={(event: MouseEvent) => {
            if (props.changesMade()) {
              event.preventDefault();
              setShowCancelConfirmationBox(true);
            } else {
              props.showOverview(false);
              window.location.href = "#machineoverview";
            }
          }}
        >
          Cancel
        </a>
      </div>
      <Show when={showUpdateConfirmationBox()}>
        <UpdateConfirmationBox
          showDialog={setShowUpdateConfirmationBox}
          updateFunction={updateMachine}
          nameToUpdate={props.updatableMachine.name}
        ></UpdateConfirmationBox>
      </Show>
      <Show when={showCancelConfirmationBox()}>
        <ChangePageConfirmationBox
          showDialog={setShowCancelConfirmationBox}
          changePage={cancelPage}
        />
      </Show>
    </div>
  );
}
