import {
  createEffect,
  createSignal,
  Match,
  onMount,
  Show,
  Switch,
} from "solid-js";
import { useI18n } from "@solid-primitives/i18n";
import { Icon } from "solid-heroicons";
import { adjustmentsHorizontal, chevronRight } from "solid-heroicons/outline";
import Plotly from "plotly.js-basic-dist";

// const _MachineStatusQuery = gql`
//   query MachineStatusQuery($machineId: ID!) {
//     getMachine(machineId: $machineId) {
//       config
//       name
//       status
//     }
//   }
// `;

// interface AuthImageProps {
//   accessToken: string;
//   alt?: string;
//   class?: string;
//   id: string;
//   src: string;
// }
//
// function AuthImage(props: AuthImageProps) {
//   function fetchWithAuthentication(url: string, token: string) {
//     const headers = new Headers();
//     // headers.set("auth_token", `${token}`);
//     // headers.set("auth_type", "USER");
//     return fetch(url, { headers });
//   }
//
//   async function displayProtectedImage(id: string, src: string, token: string) {
//     // Fetch the image.
//     const response = await fetchWithAuthentication(src, token);
//
//     // Create an object URL from the data.
//     const blob = await response.blob();
//     const objectUrl = URL.createObjectURL(blob);
//
//     // Update the source of the image.
//     const imageElement = document.getElementById(id) as HTMLImageElement;
//     if (!imageElement) {
//       console.log("Could not display image", objectUrl);
//       return;
//     }
//
//     imageElement.src = objectUrl;
//     imageElement.onload = () => URL.revokeObjectURL(objectUrl);
//   }
//
//   onMount(() => {
//     displayProtectedImage(props.id, props.src, props.accessToken);
//   });
//
//   return <img alt={props.alt} class={props.class} id={props.id} />;
// }

function AdjustmentsHorizontalIcon() {
  return (
    <Icon
      aria-hidden="true"
      class="invisible h-5 w-5 text-nl-blue-royal-900 hover:text-nl-blue-royal-700"
      path={adjustmentsHorizontal}
    />
  );
}

function ChevronRightIcon() {
  return (
    <Icon aria-hidden="true" class="h-3 w-3 inline-block" path={chevronRight} />
  );
}

interface TaskOverviewProps {
  createdAt: string;
  description: string;
  id: string;
  machine: {
    id: string;
    name: string;
    // status: number;
  };
  modelId: string | null;
  updatedAt: string;
  url: string | null;
}

export default function TaskOverview(props: TaskOverviewProps) {
  let ref!: HTMLDivElement;
  const percent = undefined; // 0.9986;
  const [t] = useI18n();
  const [taskTime, setTaskTime] = createSignal("");

  /*
  const [machine, { refetch }] = query<{
    getMachine: {
      config: string | null;
      name: string;
      status: number;
    };
  }>(_MachineStatusQuery, { machineId: props.machineId });
   */

  onMount(() => {
    const today = new Date();
    const last30Days = [...Array(30)].map(
      () => new Date(today.setDate(today.getDate() - 1)),
    );

    /*
      const rndOk = [...Array(30)].map(
        () =>
          30 +
          5 *
            Math.sqrt(-2 * Math.log(1 - Math.random())) *
            Math.cos(2 * Math.PI * Math.random()),
      );
      const rndDefect = [...Array(30)].map(
        () =>
          2 +
          3 *
            Math.sqrt(-2 * Math.log(1 - Math.random())) *
            Math.cos(2 * Math.PI * Math.random()),
      );
      const rndPending = [...Array(30)].map(
        () =>
          1 +
          10 *
            Math.sqrt(-2 * Math.log(1 - Math.random())) *
            Math.cos(2 * Math.PI * Math.random()),
      );
    */

    const dailyOkays = [...Array(30)].map(() => 0);
    const dailyDefects = [...Array(30)].map(() => 0);
    const dailyPending = [...Array(30)].map(() => 0);

    const trace1 = {
      marker: {
        color: "hsl(227,  7%, 58%)",
      },
      x: last30Days,
      y: dailyOkays.map((x) => Math.max(0, x)),
      base: 0,
      name: "OK",
      type: "bar",
    };

    const trace2 = {
      marker: {
        color: "hsl(0, 48%, 63%)",
      },
      x: last30Days,
      y: dailyDefects.map((x) => Math.max(0, x)),
      base: dailyOkays.map((x) => -Math.max(0, x)),
      hovertemplate: "%{base}",
      name: "Defects",
      type: "bar",
    };

    const trace3 = {
      marker: {
        color: "hsl(43, 72%, 68%)",
      },
      x: last30Days,
      y: dailyPending.map((x) => Math.max(0, x)),
      base: dailyOkays.map((x) => Math.max(0, x)),
      name: "Not annotated",
      type: "bar",
    };

    const data = [trace1, trace2, trace3];
    const layout = {
      barmode: "stack",
      margin: { b: 30, l: 30, r: 0, t: 0 },
      xaxis: {
        tickformat: "%b %d",
      },
    };
    Plotly.newPlot(ref, data, layout, {
      displayModeBar: false,
      responsive: true,
      staticPlot: true,
    });
  });

  createEffect(() => {
    const d = new Date(props.updatedAt);
    const [hours, minutes, seconds] = [
      d.getHours().toString().padStart(2, "0"),
      d.getMinutes().toString().padStart(2, "0"),
      d.getSeconds().toString().padStart(2, "0"),
    ];
    setTaskTime(`${hours}:${minutes}:${seconds}`);
  });

  return (
    <div class="mx-auto mb-4 max-w-7xl p-2 bg-white rounded-lg">
      <div class="flex flex-auto">
        <div class="flex flex-col mr-2">
          <Switch>
            <Match when={props.url}>
              <img
                alt=""
                class="h-28 w-64 object-cover rounded-lg"
                id={props.id}
                src={props.url ?? ""}
              />
            </Match>
            <Match when={props.url === null}>
              <div class="flex bg-white h-28 items-center justify-center">
                <p class="m4">No image</p>
              </div>
            </Match>
          </Switch>
          <h3 class="text-lg font-bold w-64 flex justify-center">
            <Show when={props.description} fallback="[name missing]">
              {props.description}
              <Show
                when={props.machine !== null && props.machine.name}
                fallback=" (Machine/Machine name missing)"
              >
                {" "}
                ({props.machine.name})
              </Show>
            </Show>
          </h3>
          <div class="flex justify-between items-end">
            <AdjustmentsHorizontalIcon />
            <div class="flex flex-col items-end">
              {/*
                <div class="items-end text-xs uppercase">
                  {t("last60mins", {}, "Last 60 minutes")}
                </div>
                <div class="items-end text-4xl font-extralight text-nl-green-900">
                  {percent ? `${percent * 100}%` : "N/A"}
                </div>
              */}
            </div>
          </div>
        </div>

        <div
          class="basis-9/12 justify-self-stretch bg-gradient-to-r from-white to-nl-pink-50 mx-2"
          ref={ref}
        />
        <div class="flex flex-col justify-between basis-1/12">
          <div class="invisible">
            <div class="self-center text-xs mb-1 uppercase font-bold text-nl-red-900">
              {taskTime()}
            </div>
            <div class="flex self-center place-items-center text-xs uppercase">
              <ChevronRightIcon />
              {t("details", {}, "Details")}
            </div>
          </div>
          <a class="nl-button nl-button--xs" href={`/inspection/${props.id}`}>
            {t("inspect", {}, "Inspect")}
          </a>
        </div>
        {/*
        <div class="basis-1/4 justify-self-stretch bg-gradient-to-l from-white to-nl-blue-slate-50 mx-2" />
        */}
      </div>
    </div>
  );
}
