import { Auth } from "aws-amplify";
import { createEffect, createSignal, Show } from "solid-js";
import { A } from "@solidjs/router"
import { createFormGroup, createFormControl } from "solid-forms";

import { TextInput } from "../../../components/TextInput";
import { validators } from "../../../helpers/validators";

function SignUpForm() {
  const [isSignedUp, setIsSignedUp] = createSignal(false);
  const group = createFormGroup({
    username: createFormControl("", {
      required: true,
      validators: [validators.required, validators.username],
    }),
    email: createFormControl("", {
      required: true,
      validators: [validators.required, validators.email],
    }),
    password: createFormControl("", {
      required: true,
      validators: [validators.required, validators.password],
    }),
  });

  createEffect(() => {
    if (group.isDisabled || !group.isValid) {
      return;
    }
  });

  const submitHandler = (e: Event) => {
    e.preventDefault();
    setIsSignedUp(false);
    console.log("submitting");
    if (group.isSubmitted) {
      console.warn("Signup form is already submitted.");
      return;
    }

    // Need to clear group-wide errors so form is submitted.
    group.setErrors(null);

    if (!group.isValid) {
      console.warn("Invalid form");
      return;
    }

    group.markPending(true);

    console.log("Signup form submitted.");

    const { username, email, password } = group.value;

    Auth.signUp({
      username: username ?? "",
      password: password ?? "",
      attributes: {
        email: email,
      },
    })
      .then((user) => {
        console.log(user);
        group.setErrors(null);
        group.markPending(false);
        group.markSubmitted(true);
        setIsSignedUp(true);
      })
      .catch((e) => {
        console.log("error signing up: ", e);
        group.markPending(false);
        group.markSubmitted(false);
        switch (e.code) {
          case "UsernameExistsException":
            group.controls.username.setErrors({
              usernameAlreadyExists: "Username already registered.",
            });
            break;
          case "InvalidPasswordException":
            group.controls.password.setErrors({
              invalidParameter:
                "Password must be at least 8 characters long and contain a symbol, a digit and an upper case character.",
            });
            break;
          // case "InvalidParameterException":
          default:
            group.setErrors({
              unknownError: "Invalid form options.",
            });
        }
      });
  };

  return (
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Show when={!isSignedUp()}>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign Up
          </h2>
          <p class="mt-2 text-center text-sm text-gray-600">
            <A
              href="/sign-in"
              class="font-medium text-nl-blue-royal-900 hover:text-nl-blue-royal-700"
            >
              Or sign in
            </A>
          </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={submitHandler} class="space-y-6">
              <div>
                <label
                  for="username"
                  class="block text-sm font-medium text-gray-700"
                >
                  Username
                </label>
                <div class="mt-1">
                  <TextInput
                    autocomplete="username"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-700 focus:outline-none focus:ring-nl-blue-royal-700 sm:text-sm"
                    control={group.controls.username}
                    name="username"
                    type="text"
                  />
                </div>
              </div>

              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div class="mt-1">
                  <TextInput
                    autocomplete="email"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-700 focus:outline-none focus:ring-nl-blue-royal-700 sm:text-sm"
                    control={group.controls.email}
                    name="email"
                    type="email"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div class="mt-1">
                  <TextInput
                    autocomplete="current-password"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-700 focus:outline-none focus:ring-nl-blue-royal-700 sm:text-sm"
                    control={group.controls.password}
                    name="password"
                    type="password"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md border border-transparent bg-nl-blue-royal-900 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-nl-blue-royal-700 focus:outline-none focus:ring-2 focus:ring-nl-blue-royal-700 focus:ring-offset-2"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </Show>

      <Show when={isSignedUp()}>
        <div class="bg-green-700 m-20 p-12 rounded-md text-white w-96">
          Thanks for signing up. We've sent a confirmation email to the address
          you entered above. You can sign in after confirming.
        </div>
      </Show>
    </div>
  );
}

export default SignUpForm;
