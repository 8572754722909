import {
  createEffect,
  createSignal,
  Show,
  Suspense,
  ErrorBoundary,
  type JSX,
  Component,
  ParentComponent,
  JSXElement,
} from "solid-js";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import OuterErrorHandler from "../OuterErrorHandler";
import { UserProvider } from "../components/UserContext";
import { useLocation } from "@solidjs/router";
import { cookieStorage, createStorageSignal } from "@solid-primitives/storage";
import { dict } from "../helpers/dictionary";
import { I18nProvider } from "./I18nProvider";

interface LayoutProps {
  children?: JSXElement;
}

export const Layout: (props: LayoutProps) => JSXElement = (
  props: LayoutProps,
) => {
  const location = useLocation();
  const [fullscreen, setFullscreen] = createSignal<boolean>();

  createEffect(() => {
    if (location.pathname.match(/\/(annotation|inspection)\/[a-z0-9]/)) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
    }
  });

  // Handles language option
  const expires = new Date();
  expires.setDate(expires.getDate() + 60);

  const cookieStoreOptions = {
    api: cookieStorage,
    deserializer: (value: string) => JSON.parse(value),
    options: {
      expires: expires,
      path: "/",
    },
    serializer: (value: string) => JSON.stringify(value),
  };

  const [language, setLanguage] = createStorageSignal(
    "lang",
    "en",
    cookieStoreOptions,
  );

  return (
    <Suspense>
      <div
        classList={{
          "flex flex-col nl-gradient": true,
          "h-screen": fullscreen(),
          "min-h-screen justify-between": !fullscreen(),
        }}
      >
        <ErrorBoundary
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          fallback={(err: any, reset: () => void) => {
            console.error(err);
            return <OuterErrorHandler error={err} reset={reset} />;
          }}
        >
          <I18nProvider dict={dict} locale={language() ?? "en"}>
            <UserProvider>
              <Header setLanguage={setLanguage} />
              <div
                classList={{
                  "flex flex-col grow": fullscreen(),
                }}
              >
                {props.children}
              </div>
              <Show when={!fullscreen()}>
                <Footer />
              </Show>
            </UserProvider>
          </I18nProvider>
        </ErrorBoundary>
      </div>
    </Suspense>
  );
};
