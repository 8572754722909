//Defines importable graphql queries where returning object is intentionally defined for use on pages

import { gql } from "@solid-primitives/graphql";

export const _InvokeEndpoint = gql`
  mutation InvokeEndpoint($taskId: ID!) {
    invokeEndpoint(taskID: $taskId) {
      images
    }
  }
`;

export const _ListDatasetsQuery = gql`
  query ListDataset {
    listDataset {
      items {
        name
        id
        annotationType
        classes {
          items {
            color
            name
            id
            modelClassId
            createdAt
          }
        }
        models {
          items {
            Model {
              name
              id
              createdAt
            }
          }
        }
        createdAt
        updatedAt
        images(limit: 500) {
          items {
            id
          }
        }
      }
    }
  }
`;

export const _ListDatasetsQueryMinimal = gql`
  query listDatasetsQuery {
    listDataset {
      items {
        annotationType
        id
        name
      }
    }
  }
`;

export const _GetDatasetImagesAndAnnotationsQuery = gql`
  query GetDataset($getDatasetId: ID!) {
  getDataset(id: $getDatasetId) {
    id
    images {
      items {
        id
        url
        imageKey
        annotations {
          items {
            annotationData {
              singleLabelClassification {
                label {
                  name
                }
              }
            }
            originStatus
          }
        }
        source {
          id
          name
        }
      }
    }
  }
}
`;

export const _ListImageAnnotationQuery = gql`
  query ListImageAnnotation {
    listImageAnnotation {
      items {
        id
        annotationData {
          annotationType
          box {
            labelId
          }
          singleLabelClassification {
            labelId
          }
        }
      }
    }
  }
`;

export const _ListTasksQuery = gql`
  query ListTask {
    listTask {
      items {
        createdAt
        description {
          description
        }
        id
        machine {
          id
          name
        }
        MachineID
        ModelID
        updatedAt
        url(size: "large")
      }
    }
  }
`;

export const _ListTasksQueryExtended = gql`
  query ListTask {
    listTask {
      items {
        id
        MachineID
        ModelID
        createdAt
        description {
          description
        }
        imageKey
        updatedAt
        url
        machine {
          id
          name
        }
        model {
          id
          name
          runtime
          datasets {
            items {
              Dataset {
                id
                name
                updatedAt
                images(limit: 500) {
                  items {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const _ListMachinesQuery = gql`
  query ListMachine {
    listMachine {
      items {
        config
        createdAt
        id
        name
        updatedAt
      }
    }
  }
`;

export const _ListSourceQuery = gql`
  query ListSource {
    listSource {
      items {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const _ListModelsQuery = gql`
  query ListModel {
    listModel {
      items {
        id
        name
        createdAt
        updatedAt
        runtime
        datasets {
          items {
            id
            createdAt
            updatedAt
            Dataset {
              id
              name
              createdAt
              updatedAt
              images(limit: 500) {
                items {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

//For Annotation and AnnotateDatasetPage
export const _GetDatasetQuery = gql`
  query getDatasetQuery($datasetId: ID!) {
    getDataset(id: $datasetId) {
      annotationType
      classes {
        items {
          color
          id
          name
        }
      }
      id
      images(limit: 500) {
        items {
          createdAt
          id
          name
          annotationComplete
          imageKey
          source {
            id
            name
          }
          annotations {
            items {
              id
              annotationData {
                annotationType
                measurement {
                  extraOutputData
                  value
                }
                singleLabelClassification {
                  label {
                    color
                    id
                    name
                  }
                }
              }
              originStatus
            }
          }
          updatedAt
          smallURL: url(size: "small")
          largeURL: url(size: "large")
        }
      }
      name
    }
  }
`;

export const _GetImageContainerQuery = gql`
  query getImageContainerQuery($imageContainerId: ID!) {
    getImageContainer(id: $imageContainerId) {
      annotations {
        items {
          annotationData {
            annotationType
            measurement {
              extraOutputData
              value
            }
            singleLabelClassification {
              label {
                color
                id
                name
              }
              confidence
            }
          }
          createdAt
          id
          needsReview
          originStatus
          updatedAt
        }
      }
      annotationComplete
      createdAt
      dataset {
        annotationType
        classes {
          items {
            color
            id
            name
          }
        }
        id
        name
      }
      id
      name
      needsReview
      updatedAt
      source {
        id
        name
      }
      smallURL: url(size: "small")
      largeURL: url(size: "large")
      originalURL: url(size: "original")
    }
  }
`;

export const _GetTaskQuery = gql`
  query getTaskQuery($getTaskId: ID!) {
    getTask(id: $getTaskId) {
      description {
        description
      }
      model {
        id
        name
        datasets {
          items {
            Dataset {
              annotationType
              classes {
                items {
                  color
                  id
                  name
                }
              }
              id
              images(limit: 500) {
                items {
                  annotationComplete
                  annotations {
                    items {
                      annotationData {
                        annotationType
                        singleLabelClassification {
                          label {
                            color
                            id
                            name
                          }
                        }
                      }
                      originStatus
                    }
                  }
                  createdAt
                  id
                  imageKey
                  name
                  source {
                    id
                    name
                  }
                  updatedAt
                  url
                }
              }
              name
            }
          }
        }
      }
    }
  }
`;
