import { useI18n } from "@solid-primitives/i18n";
import { Accessor, Switch, Match } from "solid-js";
import { DownloadAndAbortIcon } from "../../../components/Icons";
import { AnnotationTypes } from "../../Landing/types";

interface DatasetRowProps {
  annotationType: AnnotationTypes;
  id: string;
  name: string;
  downloadDataset: (id: string) => void;
  downloading: Accessor<string>;
  abort: () => void;
}

export function DatasetRow(props: DatasetRowProps) {
  const [t] = useI18n();

  return (
    <tr>
      <td class="whitespace-nowrap border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
        {props.name}
      </td>
      <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
        <Switch fallback="?">
          <Match when={props.annotationType === AnnotationTypes.Box}>
            {t("box", {}, "Box")}
          </Match>
          <Match when={props.annotationType === AnnotationTypes.Measurement}>
            {t("measurement", {}, "Measurement")}
          </Match>
          <Match when={props.annotationType === AnnotationTypes.SingleLabel}>
            {t("singleLabel", {}, "Single label")}
          </Match>
        </Switch>
      </td>
      <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8">
        <a
          href={`/annotation/dataset/${props.id}`}
          class="text-gray-500 hover:text-indigo-900 inline m-3"
        >
          {t("gallery", {}, "Gallery")}
        </a>
        <a
          href={`/annotation/${props.id}`}
          class="text-indigo-600 hover:text-indigo-900 inline m-3"
        >
          {t("annotate", {}, "Annotate")}
        </a>
        <Switch
          fallback={
            <a
              onClick={() => props.downloadDataset(props.id)}
              class="text-yellow-600 hover:text-yellow-900 inline m-3 cursor-pointer"
            >
              {t("download", {}, "Download")}
            </a>
          }
        >
          <Match when={props.downloading() === props.id}>
            <a class="inline m-7 cursor-pointer" onClick={() => props.abort()}>
              <DownloadAndAbortIcon />
            </a>
          </Match>
          <Match when={props.downloading() !== ""}>
            <a class="text-yellow-600 opacity-50 inline m-3">
              {t("download", {}, "Download")}
            </a>
          </Match>
        </Switch>
      </td>
    </tr>
  );
}
