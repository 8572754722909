import { createSignal, For, Setter, Show } from "solid-js";
import { AnnotationOriginStatus, ImageContainerComplete } from "../../../helpers/types";

interface ImageCardProps {
  image: ImageContainerComplete;
  setShowModal: Setter<boolean>;
  setPosition: (id:string) => void;
}

export default function ImageCard(props: ImageCardProps) {
  const [loadedImage, setLoadedImage] = createSignal<boolean>(true);

  return (
    <div class="relative w-36 cursor-pointer m-4 shadow-sm shadow-gray-600 hover:shadow-2xl transition-all duration-200 hover:scale-110">
      <Show when={loadedImage()}>
        <img
          class="object-contain"
          src={props.image.smallURL}
          alt=""
          onClick={() => {
            console.log("clicked");
            props.setPosition(props.image.id);
            props.setShowModal(true);
          }}
          onload={() => {
            setLoadedImage(true);
          }}
          onerror={() => {
            setLoadedImage(false);
          }}
        />
        <div class="absolute top-2 right-2 flex flex-row gap-0.5">
          <For each={props.image.annotations.items}>
            {(annotation) => (
              <Show
                when={
                  (props.image.annotations?.items ?? []).some(
                    (e) =>
                      e.originStatus === AnnotationOriginStatus.HumanGenerated,
                  )
                    ? annotation.originStatus ===
                      AnnotationOriginStatus.HumanGenerated
                    : annotation.originStatus ===
                        AnnotationOriginStatus.AiGeneratedHumanApproved ||
                      annotation.originStatus ===
                        AnnotationOriginStatus.AiGenerated
                }
              >
                  <div
                    classList={{
                      "border rounded-full p-1": true,
                      "opacity-50":
                        annotation.originStatus ===
                        AnnotationOriginStatus.AiGenerated,
                    }}
                    aria-hidden="true"
                    style={{
                      "background-color":
                      annotation.annotationData.singleLabelClassification?.label?.color || "gray",
                    }}
                  />
              </Show>
            )}
          </For>
        </div>        
        <div class="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-sm text-center">
          <span>
            {new Date(props.image.updatedAt).toLocaleTimeString("sv-SE")}
          </span>
        </div>
      </Show>

      <Show when={!loadedImage()}>
        <div
          class="flex flex-row h-40 cursor-pointer"
          onclick={() => setLoadedImage(true)}
        >
          <a class="text-blue-500 m-auto" onclick={() => setLoadedImage(true)}>
            Refetch image
          </a>
        </div>
      </Show>
    </div>
  );
}
